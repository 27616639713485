import React from "react"
import Container from "../Container"
import CircleIconButton from "../Button/CircleIconButton"
import {AiOutlineDown} from "react-icons/ai"
import {colors} from "../colors"
import styled from "styled-components"
import CarImage from "../../images/svg/car.svg"

const SectionBanner = styled(({ className, scrollTo }) => {
  return (
    <section className={className}>
      <Container>
        <div className="banner-wrapper">
          <div className="banner-content">
            <hgroup>
              <h2><span>KW</span> <span>Hybrid</span> <span>Lifeguard</span></h2>
              <h4>Wondering about the state of your <span>hybrid battery</span>?</h4>
            </hgroup>
            <div className="banner-action">
              <div className="banner-action__button">
                <CircleIconButton scrollTo={scrollTo} style={{backgroundColor:colors.secondaryGreen}} size="45" icon={<AiOutlineDown color={colors.primaryGrey} size="30" />} link="#" />
                <span>Explore</span>
              </div>
              <div className="banner-action__image">
                <img src={CarImage} alt="KW hybrid lifeguard takes care of your hybrid battery" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
})`
  height:calc(100vh - 80px);
  ${Container} {
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom:1px dashed ${colors.thirdGrey};
  }
  .banner-wrapper {
    //display: flex;
    //flex-direction: column;
    //justify-content: flex-start;
    //align-items: center;
  }
  hgroup {
    margin-bottom:1rem;
    max-width:915px;
    color:${colors.primaryGrey};
    letter-spacing: 1px;
    text-align: center;
    @media screen and (min-width: 768px){
      text-align: left;
    }
  }
  h2 {
    font-size: calc(25px + (55 - 25) * ((100vw - 300px) / (1600 - 300)));
    text-transform: uppercase;
    margin-bottom:.5rem;
    span:first-child {
      color:${colors.thirdGreen};
    }
    span:nth-child(2) {
      color:${({theme}) => theme.body === "#121212" ? colors.thirdGrey : colors.secondaryGrey};
    }
    span:last-child {
      color:${colors.primaryGreen}
    }
  }
  h4 {
    font-weight:300;
    font-size: calc(16px + (40 - 16) * ((100vw - 300px) / (1600 - 300)));
    margin-top:.5rem;
  }
  .banner-action {
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    &__button {
      display:flex;
      justify-content: center;
      align-items: center;
      width:100%;
      order:2;
      @media screen and (min-width: 768px){
        width:auto;
        justify-content: flex-start;
        order:0;
      }
      span {
        display:inline-block;
        margin-left:10px;
        color:${colors.primaryGreen};
        font-weight:300;
        font-size:20px;
      }
    }
    &__image {
      max-width:1088px;
      width:100%;
      margin:3rem auto;
      @media screen and (min-width: 768px){
        margin:auto;
        width:70%;
      }
    }
  }
`

export default SectionBanner
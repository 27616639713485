import React from "react"
import styled from "styled-components"
import { colors } from "../colors"

const CircleIconButton = styled(({ className, icon,link, style, scrollTo }) => {
  return (
    <div style={style ? style : null} className={className}>
      <a onClick={(e) => scrollTo(e)} href={link}>
        {icon}
      </a>
    </div>
  )
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width:${({size}) => size ? size + "px" : "30px"};
  height:${({size}) => size ? size + "px" : "30px"};
  border-radius:50%;
  background-color:${colors.primaryGreen};
  a {
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
  }
`

export default CircleIconButton
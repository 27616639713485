import React from "react"
import styled from "styled-components"
import Container from "../Container"
import UnimerImg from "../../images/svg/unimer.svg"
import AccumImg from "../../images/svg/akumulator.svg"
import {colors} from "../colors"
import Fade from 'react-reveal/Fade';
import Link from "gatsby-link"

const SectionDiagnostic = styled(({ className }) => {
  return (
    <section className={className}>
      <Container>
        <div className="diagnostic-box">
          <h4>1. Hybrid battery <span>diagnostic</span></h4>
          <div className="diagnostic-box__content">
            <div className="diagnostic-box__text">
              <p>We perform an analysis of your car's battery
                to detect its state, which allows us to provide
                you with a diagnostic report.</p>
              <p>Based on this report, we provide solutions
                to renew your battery. </p>
              <p>This service is free of charge.</p>
              <p>*Depending on your location, an expert
                from our team can  come to you, contact us
                to know more about this service.</p>
            </div>
            <Fade right>
              <div className="diagnostic-box__img">
                <img src={UnimerImg} alt="Hybrid battery diagnostic service" />
              </div>
            </Fade>
          </div>
        </div>
        <div className="diagnostic-box">
          <h4>2. Hybrid battery <span>repair</span></h4>
          <div className="diagnostic-box__content">
            <div className="diagnostic-box__text">
              <p>Repair of hybrid battery modules is performed with the use of the most contemporary and ecological methods. In this way, the lifespan of your battery is extended and you are taking part in our ecological process.</p>
              <p>Choose the best option for you: Repair the battery module or replace it. </p>
              <p>Upon renewing your battery with us, we provide you with up to a 2-year warranty.</p>
            </div>
            <Fade left>
              <div className="diagnostic-box__img">
                <img src={AccumImg} alt="Hybrid battery repair service" />
              </div>
            </Fade>
          </div>
        </div>
        <div className="action">
          <Link to="/contact">Get in touch</Link>
        </div>
      </Container>
    </section>
  )
})`
  padding:30px 0 0 0;
  @media screen and (min-width: 768px){
    padding-top:75px;
    padding-bottom:75px;
  }
  .diagnostic-box {
    margin:2rem 0;
    @media screen and (min-width: 768px) {
      margin:4rem 0;
    }
    h4 {
      font-weight: 200;
      font-size:calc(24px + (40 - 24) * ((100vw - 300px) / (1600 - 300)));
      margin-bottom:2rem;
      span {
        text-transform: uppercase;
        font-weight: bold;
        color:${colors.primaryGreen};
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
      align-items: center;
      img {
        display: block;
        width:100%;
      }
    }
    &__text {
      width:100%;
      order:2;
      margin:2rem 0;
      max-width:450px;
      @media screen and (min-width: 768px) {
        width:48%;
        order:0;
        margin:auto;
      }
    }
    &__img {
      width:100%;
      margin:2rem 0;
      @media screen and (min-width: 768px) {
        width:48%;
        margin:auto;
      }
    }
    &:nth-child(2) {
      h4 {
        text-align: right;
      }
      .diagnostic-box__text {
        order:2
      }
      .diagnostic-box__img {
        order:0;
      }
    }
  }
  .action {
    a {
      display: block;
      padding:.5rem 4rem;
      background-color:${colors.secondaryGreen};
      border-radius:25px;
      text-decoration: none;
      max-width:300px;
      text-align: center;
      margin:auto;
      color: ${colors.primaryGrey};
      font-weight:normal;
    }
  }
`

export default SectionDiagnostic
import React from "react"
import styled from "styled-components"
import {colors} from "../colors"
import OilImg from "../../images/svg/oilbottle.svg"
import RotoriImg from "../../images/svg/rotori.svg"
import Container from "../Container"

const SectionTireAndOil = styled(({ className }) => {
  return (
    <div className={className}>
      <img className="oilbottle" src={OilImg} alt="oil" />
      <img className="rotori" src={RotoriImg} alt="rotor" />
      <Container>
       <div style={{width:"75%", margin:"auto"}}>
         <span className="title">We can also perform a <strong>tire</strong> and <strong>oil change</strong> for your car</span>
         <span className="subtext">Contact us to find out more or visit our repair center.</span>
       </div>
      </Container>
    </div>
  )
})`
  padding:30px 0;
  margin-top:50px;
  width:100%;
  background-color:${colors.thirdGrey};
  position:relative;
  overflow: hidden;
  color:${colors.primaryGrey};
  strong {
    text-transform: uppercase;
    color:${colors.primaryGreen};
  }
  img {
    display: block;
    width:15%;
    position: absolute;
  }
  span {
    display: block;
    &.title {
      font-size:calc(24px + (40 - 24) * ((100vw - 300px) / (1600 - 300)));
      font-weight: 200;
      margin:2rem 0;
    }
    &.subtext {
      font-size:18px;
      text-align: left;
    }
  }
  .oilbottle {
    left:-40px;
    bottom:-40px;
    max-width:120px;
  }
  .rotori {
    right: -52px;
    top: -103px;
    max-width: 200px;
  }
  @media screen and (min-width: 768px){
    padding:100px 0;
  }
`

export default SectionTireAndOil
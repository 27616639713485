import React, {useRef} from "react"
import SectionBanner from "../components/HomePage/SectionBanner"
import SectionLifeguard from "../components/HomePage/SectionLifeguard"
import SectionDiagnostic from "../components/HomePage/SectionDiagnostic"
import SectionTireAndOil from "../components/HomePage/SectionTireAndOil"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = (props) => {
  console.log(props)
    const el = useRef(null);
    const scrollToSection = (e) => {
      e.preventDefault();

      el.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    return (
      <Layout path={props.location.pathname}>
          <Seo
            title="KW Hybrid Lifeguard | Extend Your Battery’s Lifespan"
            description="We evaluate the state of your car’s hybrid battery and offer solutions to regenerate or replace it. Get in touch to schedule an appointment."
            keywords="Hybrid battery, hybrid car, hybrid batteries, hybrid vehicles, hybrid battery repair, i need diagnostic for my hybrid battery, check my battery, fix my battery, battery reconditioning, hybrid battery repair, hybrid battery kitchener, car service kitchener, KW Hybrid Lifeguard"
            meta={[

            ]}
          />
          <SectionBanner scrollTo={scrollToSection} />
          <SectionLifeguard ref={el} />
          <SectionDiagnostic />
          <SectionTireAndOil />
      </Layout>
    )
}

export default IndexPage

import React, {forwardRef} from "react"
import styled from "styled-components"
import SpecH from "../Heading/SpecH"
import Container from "../Container"
import {colors} from "../colors"
import BallsImg from "../../images/svg/loptice.svg"
import LogoImg from "../../images/svg/logo.svg"
import ClockImg from "../../images/svg/clock.svg"
import ZupcImg from "../../images/svg/zupcanik.svg"
import Fade from 'react-reveal/Fade';
import Link from "gatsby-link"

const SectionLifeguard = styled(forwardRef(({ className }, ref) => {
  return (
    <section className={className}>
      <Container>
        <SpecH topPos="3"><span ref={ref}>Lifeguard</span> for hybrid batteries</SpecH>
        <p>We specialize in the regeneration and replacement of batteries for hybrid vehicles.</p>
        <p>We use the most advanced technologies to recondition and rebuild the batteries,
          extending their lifespan and at the same time making a positive impact on the environment.</p>
        <p>Our goal is to provide our customers with the best possible service,
          making customer satisfaction our priority.</p>
        <div className="iconbox-list">
          <div className="iconbox">
            <Fade bottom>
              <div className="icon">
                <img src={BallsImg} alt="KW hybrid lifeguard service is economical" />
              </div>
            </Fade>
            <div>
              <span><strong>E</strong>conomical</span>
            </div>
          </div>
          <div className="iconbox">
            <Fade bottom delay={300}>
              <div className="icon">
                <img style={{width:"60px"}} src={LogoImg} alt="KW hybrid lifeguard service is eco friendly
" />
              </div>
            </Fade>
            <div>
              <span><strong>E</strong>co-friendly</span>
            </div>
          </div>
          <div className="iconbox">
            <Fade bottom delay={600}>
              <div className="icon">
                <img style={{width:"60px"}} src={ClockImg} alt="KW hybrid lifeguard service is efficient
" />
              </div>
            </Fade>
            <div>
              <span><strong>E</strong>fficient</span>
            </div>
          </div>
        </div>
        <div className="inner-banner">
          <img src={ZupcImg} alt="KW hybrid lifeguard service expands your battery’s lifespan" />
          <div className="inner-banner__content">
            <h4 className="inner-banner__title">Extend your battery’s lifespan</h4>
            <Link to="/contact" className="inner-banner__btn">Schedule an appointment</Link>
          </div>
        </div>
      </Container>
    </section>
  )
}))`
  padding:30px 0;
  text-align: center;
  @media screen and (min-width: 768px) {
    padding:100px 0 0 0;
  }
  ${SpecH} {
    margin-bottom:30px;
    @media screen and (min-width: 768px) {
      margin-bottom:75px;
    }
    h3 {
      font-size:calc(24px + (40 - 24) * ((100vw - 300px) / (1600 - 300)));
    }
    span {
      color: ${colors.primaryGreen};
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .iconbox-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    width:70%;
    max-width:800px;
    margin:5rem auto;
  }
  .iconbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:100%;
    margin-bottom:2rem;
    @media screen and (min-width: 768px) {
      width:33%;
      margin-bottom:0;
    }
    .icon {
      width:100%;
      min-height:75px;
      img {
        display: block;
        width:100%;
        max-width:75px;
        margin:auto;
      }
    }
    span {
      display: block;
      margin:.5rem 0;
      font-size:1.7rem;
      font-weight: 200;
    }
    strong {
      color: ${colors.primaryGreen};
    }
  }
  .inner-banner {
    position:relative;
    background-color:${colors.thirdGrey};
    padding:2rem 0;
    width:100%;
    img {
      display: block;
      position:absolute;
      left:2%;
      bottom:2%;
      max-width:160px;
      width:20%;
    }
    &__content {
      position:relative;
      z-index: 10;
      //padding:0 1rem;
      text-align: center;
      a {
        // padding:1rem 3rem;
        // background-color:${colors.secondaryGreen};
        // border:none;
        // border-radius:23px;
        display: block;
        margin:auto;
        max-width: 405px;
        width:62%;
        text-decoration: none;
        color:${colors.primaryGrey};
      }
    }
    &__title {
      text-transform: uppercase;
      font-weight:bold;
      margin-top:0;
      color:${colors.primaryGreen};
      font-size:calc(24px + (27 - 24) * ((100vw - 300px) / (1600 - 300)));
    }
    &__btn {
      padding:1rem 3rem;
      //font-size:1rem;
      background-color:${colors.secondaryGreen};
      border:none;
      border-radius:23px;
    }
  }
`

export default SectionLifeguard
